import {
  AnalyticsCustomEventNames,
  AppStoreProviders,
  BureauProviders,
} from "../../types/analytics";

export const AMPLITUDE_KEY = process.env.GATSBY_AMPLITUDE_SDK_KEY || "";
export const APP_INSIGHTS_INSTRUMENTATION_KEY =
  process.env.GATSBY_APP_INSIGHTS_INSTRUMENTATION_KEY || "";
export const AMPLITUDE_APPLICATION = "Wisr dashboard";
export const GTM_TRACKER_ID = process.env.GATSBY_GTM_TRACKER || "UA-52084252-5";
export const EVENT = {
  WIDGETLOAD: "widgetLoaded" as const,
  VIEWPAGE: "viewedPage" as const,
  SUBMITFORM: "submittedForm" as const,
  CLICKBUTTON: "clickedButton" as const,
  VIEWCREDITSCORES: "viewedDashboardCreditScores" as const,
  LOADCREDITWIDGET: "loadedWidgetCreditScores" as const,
  LOADLOANWIDGET: "loadedLoanWidget" as const,
};

// TODO: merge events into Event
export const ApplicationEvents = {
  USER_ERROR: "Received user error",
  SERVER_ERROR: "Received server error",
  API_ERROR: "Received api error",
  VIEWED_APP_FORM: "Viewed loan app form",
  VIEWED_APPLICATION_FORM: "Viewed loan application form",
  CONTINUE_TO_BANK_STATEMENTS: "Continued to bank statements",
  VIEWED_BANK_STATEMENTS: "Viewed loan application bank statements page",
  CONTINUE_TO_CONFIRMATION: "Continued to confirmation",
  SUBMIT_APPLICATION: "Submitted loan application",
} as const;

export const STORE_TYPE: { [key: string]: AppStoreProviders } = {
  APPLE: "Apple",
  GOOGLE: "Google",
} as const;
export const BUREAU_TYPE: { [key: string]: BureauProviders } = {
  EXPERIAN: "Experian",
  EQUIFAX: "Equifax",
} as const;

export const QUOTE_APPLICATION = "Wisr website";

export const LoanStatus = {
  QUOTE_STARTED: "Started estimate",
  QUOTE_SUBMITTED: "Submitted estimate",
  ESTIMATE_PRESENTED: "Presented estimate",
  APPLICATION_STARTED: "Started application",
  CONTINUED_TO_BANK_STATEMENTS: "Continued to bank statements",
  STARTED_BANK_STATEMENTS: "Viewed bank statements page",
  CONTINUED_TO_CONFIRMATION: "Continued to confirmation",
  STARTED_APP_CONFIRMATION: "Started application confirmation",
  SUBMIT_APPLICATION: "Submitted application",
  PRESUBMIT_NSR_FAIL: "Pre-submit NSR fail",
} as const;

export const RateEstimatePages = {
  PURPOSE: "loan purpose",
  CAR_FOUND: "found car",
  CAR_YEAR: "car manufacture year",
  CAR_SELLER: "car seller",
  CAR_AGE: "car age",
  ASSET_SECURITY: "asset security",
  AMOUNT: "loan amount",
  ABOUT_YOU: "about you",
  RELATIONSHIP_STATUS: "marital status",
  LIVING_SITUATION: "living arrangement",
  INVESTMENT_OWNED: "own investment property",
  INVESTMENT_MORTGAGED: "investment mortgaged",
  CURRENT_ADDRESS: "address",
  CURRENT_MOVED_IN: "how long at address",
  PREVIOUS_ADDRESS: "previous address",
  PREVIOUS_MOVED_IN: "how long at previous address",
  CURRENT_EMPLOYMENT_STATUS: "employment status",
  CURRENT_EMPLOYMENT_LENGTH: "how long at employment",
  PREVIOUS_EMPLOYMENT_STATUS: "previous employment status",
  PREVIOUS_EMPLOYMENT_LENGTH: "how long at previous employment",
  INCOME: "income",
  NO_MATCH: "no match",
  IDENTIFICATION: "identification",
  REVIEW: "review",
};

export const AppFormPages = {
  ABOUT_YOU: "about you",
  REQUIREMENTS: "requirements and objectives",
  ID_DOCS: "your id documents",
  DRIVER_LICENCE: "driver licence",
  PASSPORT: "passport",
  MEDICARE: "medicare",
  LIVING: "living situation",
  EMPLOYMENT: "employment and income",
  PREVIOUS_EMPLOYMENT: "previous employment",
  OTHER_INCOME: "other income",
  SPENDING: "your spending",
  DEBTS: "your debts",
  DEBT_LIST: "debt list",
  STATEMENTS: "your bank statements",
  REVIEW: "review application",
  SIGNIFICANT_CHANGES: "significant changes",
  LOAN_TERM: "loan term",
  BELOW_NSR: "below NSR",
  SUBMISSION_LOADING: "submission loading",
};

export const Events = {
  PRESENTED_DRIVA: "Presented Driva offer" as AnalyticsCustomEventNames,
  COMPLETED_ESTIMATE: "Submitted estimate",
  VIEWED_ESTIMATE: "Viewed estimate form",
  USER_ERROR: "Received user error",
  SERVER_ERROR: "Received server error",
  VIEWED_SECOND_LOAN_ESTIMATE: "Viewed second loan estimate form",
};
