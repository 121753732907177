import React from "react";
import { AppFormPages } from "../../../shared/analytics/analytics.consts";

import { InfoModal } from "../info-modal/info-modal";
import { sendAppFormViewedEvent } from "../multi-page-application-form.utils";
import style from "./nsr-modal.scss";
import { BRAND_WEBSITE } from "../../../shared/url.consts";

interface PropTypes {
  nsrModal: boolean;
  setNsrModal: (arg0: boolean) => void;
}

export const NsrModal: React.FC<PropTypes> = ({ nsrModal, setNsrModal }) => {
  const heading = "Wisr can't offer you a loan right now";
  const description = (
    <div className="nsr-description">
      <style jsx>{style}</style>
      <p>
        Based on what you&rsquo;ve told us, you may find it difficult to repay
        the loan amount requested.
      </p>
      <p>
        <strong>Your credit score has not been impacted.</strong>
      </p>
      <p>
        <strong>What can you do?</strong>
      </p>
      <p>
        Your first step is to double check your application. If you spot an
        error, you can edit and resubmit it.
      </p>
      <ul>
        <li>Check that you&rsquo;ve included all your sources of income</li>
        <li>Make sure you&rsquo;ve calculated your expenses correctly</li>
        <li>
          Double check the amounts you entered for your income, debts and
          expenses
        </li>
        <li>Review the loan amount and term you&rsquo;ve requested</li>
      </ul>
      <p>
        If you&rsquo;ve entered all your information correctly, you can use our{" "}
        <a
          href={`${BRAND_WEBSITE}/loans/personal-loans/personal-loan-calculator`}
          target="_blank"
          rel="noopener nofollow"
        >
          loan calculator
        </a>{" "}
        to get a clearer idea of how much a loan will cost you each month.
      </p>
      <p>
        As a responsible lender, Wisr will never let you take on a loan that
        could put you into financial difficulty.
      </p>
    </div>
  );

  React.useEffect(() => {
    if (nsrModal) {
      sendAppFormViewedEvent(AppFormPages.BELOW_NSR);
    }
  }, [nsrModal]);

  return (
    <InfoModal
      infoModal={nsrModal}
      setInfoModal={setNsrModal}
      heading={heading}
      description={description}
      buttonText="Review application"
    />
  );
};
