import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button } from "@wisr/react-ui";
import React from "react";
import { useDispatch } from "react-redux";

import { useAggregator } from "../../../../aggregator/aggregator.hooks";
import { ContextCard } from "../../../../context-card/context-card";
import { ContextCardApplicationCopies } from "../../../../context-card/context-card.constants";
import {
  ApplicationEvents,
  LoanStatus,
  AppFormPages,
} from "../../../../shared/analytics/analytics.consts";
import {
  gtmConversion,
  gtmEcommerce,
  sendGtmEvent,
  quoteAmplitudeHelper,
} from "../../../../shared/analytics/analytics.utils";

import { Application } from "../../../application";
import {
  checkForFormLevelErrors,
  sendLoanApplicationAmplitudeEvent,
} from "../../../application.utils";
import style from "../../multi-page-application-form.scss";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import { NsrModal } from "../../nsr-modal/nsr-modal";
import { ReviewAccordion } from "./review-accordion/review-accordion";
import reviewStyle from "./review-your-details.scss";
import {
  getApplicationFormStateErrors,
  getApplicationLocalErrors,
  hasSectionErrorsReview,
  logDebugValidationsReview,
} from "./review-your-details.utils";
import { TermsList } from "./terms-list/terms-list";
import { mapToLoanApplicationRequest } from "../../../../redux/loan-application/loan-application.utils";
import {
  saveLoanApplication,
  submitLoanApplication,
} from "../../../../redux/loan-application/loan-application.thunk";
import { useGetWisrApplicationId } from "../../../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.hooks";
import { useGetRateEstimateState } from "../../../../redux/rate-estimate/rate-estimate.hooks";
import { FetchStatus } from "../../../../shared/redux.consts";
import { fetchRateEstimate } from "../../../../redux/rate-estimate/rate-estimate.thunk";
import { useGetLoanApplicationState } from "../../../../redux/loan-application/loan-application.hooks";
import { fetchLoanApplicationStatus } from "../../../../redux/loan-application-status/loan-application-status.thunk";
import { useGetLoanApplicationStatusState } from "../../../../redux/loan-application-status/loan-application-status.hooks";
import { resetLoanApplicationStatusState } from "../../../../redux/loan-application-status/loan-application-status.slice";
import { useLoanTerm } from "../../hooks/use-is-editing";
import { LoanApplicationStatus } from "../../../../redux/loan-application-status/loan-application-status.consts";
import { navigate } from "gatsby";
import { LoaderFullPage } from "../../../../shared/loader-full-page/loader-full-page";
import { useQueryString } from "../../../../shared/gatsby.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  submitAdapter: UseFormTools<Application>["submitAdapter"];
  setValue: UseFormTools<Application>["setValue"];
  setFormErrors: (arg0: string[]) => void;
}

export const ReviewYourDetails: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  submitAdapter,
  setValue,
  setFormErrors,
  location,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nsrModal, setNsrModal] = React.useState(false);
  const [submitClicked, setSubmitClicked] = React.useState(false);
  const dispatch = useDispatch();
  const aggregator = useAggregator();
  const wisrApplicationId = useGetWisrApplicationId();
  const loanApplicationState = useGetLoanApplicationState();
  const loanApplicationStatusState = useGetLoanApplicationStatusState();
  const termParam = useLoanTerm(location);
  const params = useQueryString(location);
  const statusParam = params.status;
  const rateEstimateState = useGetRateEstimateState();
  const rateEstimateOffers =
    rateEstimateState.rateEstimateResponse?.rateEstimate?.indicativeQuotes;
  const isDisabled =
    !formData.termsAndConditionsAgreement.length ||
    loanApplicationState.postStatus === FetchStatus.LOADING;
  const FETCH_STATUS_DELAY = 3000;

  const onSubmit = submitAdapter(
    /* istanbul ignore next */
    (formValid, submittedFormData, formState) => {
      const localErrors = checkForFormLevelErrors(formData);
      const sectionValid = !hasSectionErrorsReview(localErrors);

      if (formValid && sectionValid) {
        sendLoanApplicationAmplitudeEvent({
          eventName: ApplicationEvents.SUBMIT_APPLICATION,
          loanId: formData.loanId,
          loanPurpose: formData.loanPurpose,
          status: LoanStatus.SUBMIT_APPLICATION,
        });
        const userType = "direct";

        gtmConversion(
          userType,
          +formData.loanId,
          aggregator?.name || null,
          +formData.loanAmount,
          formData.loanPurpose
        );
        gtmEcommerce(
          +formData.loanId,
          aggregator?.name || null,
          +formData.loanAmount,
          formData.loanPurpose,
          userType
        );
        sendGtmEvent({
          event: "conversionFunnel",
          loanAmount: formData.loanAmount,
          interestRate: "",
          loanTerm: formData.loanTerm,
          loanPurpose: formData.loanPurpose,
          maritalStatus: formData.maritalStatus,
          creditHistory: "",
          stepNumber: "5",
          stepName: "Submitted application",
          appStatus: "Submitted",
          email: formData.email,
        });
        const loanApplication = mapToLoanApplicationRequest(formData);
        dispatch(submitLoanApplication({ wisrApplicationId, loanApplication }));
      } else {
        quoteAmplitudeHelper.sendEvent(ApplicationEvents.USER_ERROR, {
          Type: "Incomplete Application Form",
          Page: window.location.href,
          "Loan id": formData.loanId,
          Info: localErrors,
          "Section valid": sectionValid,
          "Form valid": formValid,
          "Form state errors": getApplicationFormStateErrors(formState),
        });
        setSubmitClicked(false);
      }
      const formStateErrorMessages = getApplicationFormStateErrors(
        formState,
        true
      );
      const localErrorMessages = getApplicationLocalErrors(localErrors, true);
      setFormErrors(formStateErrorMessages.concat(localErrorMessages));
      logDebugValidationsReview(formState, localErrors);

      return Promise.resolve();
    }
  );

  React.useEffect(() => {
    if (rateEstimateState.getStatus === FetchStatus.IDLE && wisrApplicationId) {
      dispatch(
        fetchRateEstimate({
          wisrApplicationId: wisrApplicationId,
          partnerCompanyId: formData.partnerCompanyId,
        })
      );
    }
  }, [
    dispatch,
    formData.partnerCompanyId,
    rateEstimateState.getStatus,
    wisrApplicationId,
  ]);

  React.useEffect(() => {
    if (rateEstimateOffers && rateEstimateOffers.length > 0) {
      const loanTerm = termParam ?? formData.loanTerm;
      const offerId = rateEstimateOffers.find(
        (offer) => offer.term.toString() === loanTerm
      )?.id;
      if (offerId && offerId !== formData.offerId) {
        if (termParam) {
          setValue("loanTerm", termParam);
        }
        setValue("offerId", offerId);
        const loanApplication = mapToLoanApplicationRequest({
          ...formData,
          offerId: offerId,
          loanTerm: loanTerm,
        });
        dispatch(saveLoanApplication({ wisrApplicationId, loanApplication }));
      }
    }
  }, [
    dispatch,
    formData,
    rateEstimateOffers,
    setValue,
    termParam,
    wisrApplicationId,
  ]);

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.REVIEW);
  }, []);

  React.useEffect(() => {
    if (
      submitClicked &&
      loanApplicationState.postStatus === FetchStatus.SUCCESS &&
      loanApplicationState.wisrApplicationId &&
      loanApplicationState.wisrApplicationId !== ""
    ) {
      setTimeout(() => {
        dispatch(fetchLoanApplicationStatus(wisrApplicationId));
      }, FETCH_STATUS_DELAY);
    }
  }, [
    dispatch,
    loanApplicationState.postStatus,
    loanApplicationState.wisrApplicationId,
    submitClicked,
    wisrApplicationId,
  ]);

  React.useEffect(() => {
    if (
      submitClicked &&
      loanApplicationState.postStatus === FetchStatus.SUCCESS &&
      loanApplicationStatusState.status === FetchStatus.SUCCESS &&
      loanApplicationState.wisrApplicationId
    ) {
      dispatch(resetLoanApplicationStatusState());
      setSubmitClicked(false);
      navigate("/application/submitted");
    } else if (
      submitClicked &&
      Array.isArray(loanApplicationState.error) &&
      loanApplicationState.error.length > 0
    ) {
      setSubmitClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    loanApplicationState.postStatus,
    loanApplicationState.wisrApplicationId,
    loanApplicationStatusState.loanApplicationStatus,
    loanApplicationStatusState.status,
  ]);

  React.useEffect(() => {
    if (
      typeof statusParam === "string" &&
      statusParam === LoanApplicationStatus.PRE_SUBMIT_NSR_FAILED
    ) {
      setNsrModal(true);
      dispatch(resetLoanApplicationStatusState());
    }
  }, [dispatch, statusParam, wisrApplicationId]);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{reviewStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">Review your details</h2>
            <p>Please check all your details are correct.</p>
          </div>

          <div className="input-wrapper">
            <ReviewAccordion formData={formData} />
          </div>
        </div>

        <ContextCard
          copy={ContextCardApplicationCopies.AWARD_WINNER}
          form="application"
        />

        <TermsList fieldAdapter={fieldAdapter} setValue={setValue} />

        <div className="button-wrapper">
          <form noValidate onSubmit={onSubmit}>
            <Button
              button="primary"
              theme="navy"
              size="large"
              fullWidth={true}
              disabled={isDisabled}
            >
              <button
                type="submit"
                className="next-page-button"
                onClick={() => setSubmitClicked(true)}
                disabled={isDisabled}
              >
                Submit Application
              </button>
            </Button>
          </form>
          <p className="hard-enquiry-copy">
            We&apos;ll need to do a &apos;hard enquiry&apos; on your credit file
            to assess your application, this may impact your credit score.
          </p>
        </div>
      </div>
      <NsrModal nsrModal={nsrModal} setNsrModal={setNsrModal} />
      <LoaderFullPage enabled={submitClicked} />
    </>
  );
};
